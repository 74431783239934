import { Heading } from '@chakra-ui/react';

export const Error404Page = () => {
  return (
    <>
      <main className="tw-pt-20 tw-pb-[120px]">
        <header className="tw-flex tw-justify-center">
          <Heading as="h1">404</Heading>
        </header>

        <p className="tw-mt-10 tw-text-center">
          お探しになったページは存在しません
        </p>
      </main>
    </>
  );
};
